@layer base {
  .title {
  margin: var(--spacer-400) 0 var(--spacer-300);
}

.article {
  margin-bottom: calc(var(--spacer-400) * 2);
}

.image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: var(--spacer-300);
}

.text {
  margin-top: var(--spacer-200);
}

.text ul {
  margin: 0;
  padding-left: var(--spacer-200);
}

.text ul li:not(:last-child) {
  padding-bottom: var(--spacer-50);
}

.ctaTitle {
  margin: var(--spacer-200) 0;
}

.ctaWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacer-100);
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer {
  grid-column: 1 / span 2;
  width: 80%;
  margin: 0 auto var(--spacer-400);
}

@media (--medium-viewport) {
  .section {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: var(--spacer-800);
  }

  .title {
    grid-column-start: 2;
    margin-bottom: var(--spacer-700);
    margin-left: var(--spacer-400);
  }

  .article {
    display: grid;
    grid-column: 1 / span 2;
    grid-template-columns: 50% 50%;
  }

  .content {
    position: relative;
    margin-left: var(--spacer-400);
    padding-left: var(--spacer-400);
  }

  .content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--spacer-50);
    height: 100%;
    opacity: 0;
    background-color: var(--color-purple--500);
  }

  .current .content::before {
    transition: opacity 1s ease-out;
    opacity: 1;
  }

  .ctaTitle {
    margin-top: var(--spacer-400);
  }

  .medias {
    position: relative;
  }

  .image {
    position: absolute;
    right: var(--spacer-100);
    bottom: -40px;
    width: auto;
    max-height: 450px;
    margin: 0;
  }

  .footer {
    width: 60%;
    padding-top: var(--spacer-400);
  }

  .footerText {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .button {
    margin-top: var(--spacer-400);
  }
}

@media (--large-viewport) {
  .article:not(:last-of-type) {
    margin-bottom: calc(var(--spacer-800) * 4);
  }

  .content {
    margin-left: calc(var(--spacer-400) * 2);
    padding-left: calc(var(--spacer-400) * 2);
  }

  .title {
    margin-left: calc(var(--spacer-400) * 2);
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .articleTitle {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .image {
    max-height: 500px;
  }
}

}