@layer modules, ui, base;
@layer base {
  .VerticalSlider_title__g6TjO {
  margin: var(--spacer-400) 0 var(--spacer-300);
}

.VerticalSlider_article__4TMFh {
  margin-bottom: calc(var(--spacer-400) * 2);
}

.VerticalSlider_image__XDsr4 {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: var(--spacer-300);
}

.VerticalSlider_text__p4z_i {
  margin-top: var(--spacer-200);
}

.VerticalSlider_text__p4z_i ul {
  margin: 0;
  padding-left: var(--spacer-200);
}

.VerticalSlider_text__p4z_i ul li:not(:last-child) {
  padding-bottom: var(--spacer-50);
}

.VerticalSlider_ctaTitle__og_ox {
  margin: var(--spacer-200) 0;
}

.VerticalSlider_ctaWrapper__kiFj2 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacer-100);
  margin: 0;
  padding: 0;
  list-style: none;
}

.VerticalSlider_footer__e_iUg {
  grid-column: 1 / span 2;
  width: 80%;
  margin: 0 auto var(--spacer-400);
}

@media (min-width: 48rem) {
  .VerticalSlider_section__fOGLu {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: var(--spacer-800);
  }

  .VerticalSlider_title__g6TjO {
    grid-column-start: 2;
    margin-bottom: var(--spacer-700);
    margin-left: var(--spacer-400);
  }

  .VerticalSlider_article__4TMFh {
    display: grid;
    grid-column: 1 / span 2;
    grid-template-columns: 50% 50%;
  }

  .VerticalSlider_content__RkCu3 {
    position: relative;
    margin-left: var(--spacer-400);
    padding-left: var(--spacer-400);
  }

  .VerticalSlider_content__RkCu3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--spacer-50);
    height: 100%;
    opacity: 0;
    background-color: var(--color-purple--500);
  }

  .VerticalSlider_current__E2VQm .VerticalSlider_content__RkCu3::before {
    transition: opacity 1s ease-out;
    opacity: 1;
  }

  .VerticalSlider_ctaTitle__og_ox {
    margin-top: var(--spacer-400);
  }

  .VerticalSlider_medias__y1k5n {
    position: relative;
  }

  .VerticalSlider_image__XDsr4 {
    position: absolute;
    right: var(--spacer-100);
    bottom: -40px;
    width: auto;
    max-height: 450px;
    margin: 0;
  }

  .VerticalSlider_footer__e_iUg {
    width: 60%;
    padding-top: var(--spacer-400);
  }

  .VerticalSlider_footerText__gQfxC {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .VerticalSlider_button__8pA7O {
    margin-top: var(--spacer-400);
  }
}

@media (min-width: 64rem) {
  .VerticalSlider_article__4TMFh:not(:last-of-type) {
    margin-bottom: calc(var(--spacer-800) * 4);
  }

  .VerticalSlider_content__RkCu3 {
    margin-left: calc(var(--spacer-400) * 2);
    padding-left: calc(var(--spacer-400) * 2);
  }

  .VerticalSlider_title__g6TjO {
    margin-left: calc(var(--spacer-400) * 2);
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .VerticalSlider_articleTitle__uBtCk {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .VerticalSlider_image__XDsr4 {
    max-height: 500px;
  }
}

}
